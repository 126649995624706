import React, { useEffect } from 'react';

type WidgetProps = {
  id: string;
  type: string;
};

export default function Widget({ id, type }: WidgetProps) {
  useEffect(() => {
    let script = document.createElement('script');
    script.src = 'https://www.fussball.de/widgets.js';
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="fussballde_widget" data-id={id} data-type={type}></div>
  );
}
